<template>
    <div>
        <div class="pageheader pd-t-25 pd-b-15">
            <div class="row">
                <div class="col-md-4 mb-2 ">
                    <div class="pd-t-5 pd-b-5">
                        <h1 class="pd-0 mg-0 tx-20 text-overflow">Data Mahasiswa Selesai/Undur Diri</h1>
                    </div>
                    <div class="breadcrumb pd-0 mg-0">
                        <a class="breadcrumb-item" href="#"><i class="fa fa-users mg-r-5"></i><span class="breadcrumb-item active"> Data Mahasiswa Lulus/Undur Diri</span></a>
                    </div>
                </div>
            </div>
            
        </div> 
        <!-- MODALS ================================================== -->
        <modal name="my-modal" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h5 class="card-header-title">
                            Detail Kemajuan
                        </h5>
                    </div>
                    <form @submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="card-body">
                            <div class="form-group">
                                <div class="row align-items-center">
                                    <label class="col-12">Nama Lengkap</label>
                                    <div class="col-12">
                                        <input type="text" disabled v-model="form.name" value="" class="form-control" placeholder="" required="">
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="form-group">
                                <div class="row align-items-center">
                                    <label class="col-6">Awal Studi</label>
                                    <label class="col-6">Tahapan</label>
                                    <div class="col-6">
                                        <div class="input-group">
                                            <select class="col-6 form-control" disabled required="" v-model="form.awal_studi_bulan">
                                                <option value="01">Januari</option>
                                                <option value="08">Agustus</option>
                                            </select>
                                            <input type="number" disabled v-model="form.awal_studi_tahun" value="" class="form-control" placeholder="2023" required="">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <select class="form-control" required="" disabled v-model="form.id_tahapan">
                                            <option value="">- Pilih Tahapan -</option>
                                            <option v-for="(item, index) in dataTahapan" :key="index" :value="item.id">{{ item.nama_kegiatan }}</option>
                                        </select>
                                    </div>
                                    <label class="mt-2 col-12">Pembimbing</label>
                                    <div class="col-12">
                                        <textarea v-model="form.list_pembimbing" required="" disabled class="form-control"></textarea>
                                    </div>
                                    <label class="mt-2 col-12">Judul</label>
                                    <div class="col-12">
                                        <textarea v-model="form.judul" required="" disabled class="form-control"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_modal()">Tutup</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <modal name="detail-tahapan" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h5 class="card-header-title">
                            Detail Progress Kemajuan 
                        </h5>
                    </div>
                    <form @submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="card-body">
                            <div class="form-group">
                                <div class="row align-items-center">
                                    <div class="col-3">
                                        <input type="text" disabled v-model="detail.nim" value="" class="form-control" placeholder="" required="">
                                    </div>
                                    <div class="col-9">
                                        <input type="text" disabled v-model="detail.name" value="" class="form-control" placeholder="" required="">
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="form-group">
                                <div v-for="(item, index) in detailTahapan" :key="index" class="baseline baseline-border">
                                    <div class="baseline-list baseline-border baseline-success">
                                        <div class="row baseline-info">
                                            <div class="col-6">
                                                <span v-if="item.pernyataan == 1" class="mb-0 tx-gray-500">{{ convertDate(item.tanggal) }}</span>
                                                <span v-if="item.pernyataan == 0" class="mb-0 tx-gray-500">{{ onlyDate(item.tanggal) }}</span>
                                                <h2 class="tx-16 tx-dark">{{ item.tahapan }}</h2>
                                            </div>
                                            <div class="col-6">
                                                <ul class="list-unstyled mg-t-10 mg-0 d-flex">
                                                    <li v-if="item.permohonan != null">
                                                        <div class="avatar"><span class="bg-soft-danger avatar-initial rounded-circle bg-primary"><a :href="$apiconfig + 'uploads/permohonan/' + item.permohonan" target="_blank" style="cursor:pointer" class="tooltipin">📄<span class="tooltiptext">Surat Permohonan</span></a></span></div>
                                                    </li>
                                                    <li v-if="item.undangan != null" class="mg-l--10">
                                                        <div class="avatar"><span class="bg-soft-primary avatar-initial rounded-circle bg-primary"><a :href="$apiconfig + 'uploads/undangan/' + item.undangan" target="_blank" style="cursor:pointer" class="tooltipin">📄<span class="tooltiptext">Surat Undangan</span></a></span></div>
                                                    </li>
                                                    <li v-if="item.pernyataan == 1" class="mg-l--10">
                                                        <div class="avatar"><span class="bg-soft-warning avatar-initial rounded-circle bg-primary"><a style="cursor:pointer" @mouseover="updateSurat(item.id)" @click="generateReport" class="tooltipin">📄<span class="tooltiptext">Surat Pernyataan</span></a></span></div>
                                                    </li>
                                                    <li v-if="item.berita_acara != null" class="mg-l--10">
                                                        <div class="avatar"><span class="bg-soft-success avatar-initial rounded-circle bg-primary"><a :href="$apiconfig + 'uploads/berita_acara/' + item.berita_acara" target="_blank" style="cursor:pointer" class="tooltipin">📄<span class="tooltiptext">Berita Acara</span></a></span></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="detailTahapan == null" class="alert alert-info alert-bordered pd-y-15" role="alert">
                                    <div class="d-sm-flex align-items-center justify-content-start">
                                        <i class="icon ion-ios-information alert-icon tx-52 mg-r-20"></i>
                                        <div class="mg-t-20 mg-sm-t-0">
                                        <h5 class="mg-b-2">Belum Ada Progress yang dilalui.</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_detail()">Tutup</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <!-- CONTENT -->
                <div class="tab-content mb-3">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <div class="col-md-4 pl-0 pr-0">
                                    <div class="input-group input-group-flush input-group-merge">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <span class="fe fe-users mr-2"></span>{{ totalPengguna }} Mahasiswa
                                            </div>
                                        </div>
                                        <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari Mahasiswa...">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <span class="fe fe-search"></span>
                                            </div>
                                        </div>
                                        <!-- HEADER -->
                                    </div>
                                </div>
                                <div class="ml-2 col-md-2 pl-0 pr-0">
                                    <select @change.prevent="getData()" v-model="filterPengutamaan" class="form-control">
                                        <option value="">- Semua Pengutamaan -</option>
                                        <option v-for="(item, index) in dataPengutamaan" :key="index" :value="item.id">{{ item.nama_pengutamaan }}</option>
                                    </select>
                                </div>
                                <div class="ml-2 col-md-2 pl-0">
                                    <select @change.prevent="getData()" v-model="filterTahapan" class="form-control">
                                        <option value="">- Semua Tahapan -</option>
                                        <option value="lulus">Selesai</option>
                                        <option value="undri">Undur Diri</option>
                                    </select>
                                </div>
                                <div class="ml-2 col-md-2 pl-0">
                                    <select @change.prevent="getData()" v-model="filterAngkatan" class="form-control">
                                        <option value="">- Semua Angkatan -</option>
                                        <option value="2019">2019</option>
                                        <option value="2020">2020</option>
                                        <option value="2021">2021</option>
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                    </select>
                                </div>
                                <div class="ml-2 col-md-2 pl-0">
                                    <download-excel :fetch="getDataExport" :name="fileName" :header="header">
                                        <button class="btn btn-success text-sm"><span class="fe fe-download mr-2"></span>Export</button>
                                    </download-excel>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-xl table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>NIM</th>
                                            <th>NAMA</th>
                                            <th>PEMBIMBING</th>
                                            <th>Tahapan Lulus</th>
                                            <th>Studi Berjalan</th>
                                            <th class="text-center text-dager">Batas Maksimal<br> Selanjutnya</th>
                                            <th>Studi Maksimal</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(user, index) in dataPengguna" :key="index">
                                            <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                            <td>
                                                {{ user.nim }}
                                            </td>
                                            <td>
                                                {{ user.name }}<br>
                                                <span class="pt-0 pb-0 btn btn-sm btn-oblong btn-outline-info">{{ user.pengutamaan }}</span>
                                            </td>
                                            <td>
                                                {{ user.pembimbing[0] }}<br>
                                                {{ user.pembimbing[1] }}<br>
                                                {{ user.pembimbing[2] }}
                                            </td>
                                            <td>
                                                <span v-if="user.id_tahapan == 1" @click.prevent="progressForm(user.id, user.nim, user.name)" class="btn btn-sm btn-oblong btn-secondary btn-block mg-b-10">{{ user.tahapan }}</span>
                                                <span v-if="user.id_tahapan == 2" @click.prevent="progressForm(user.id, user.nim, user.name)" class="btn btn-sm btn-oblong btn-danger btn-block mg-b-10">{{ user.tahapan }}</span>
                                                <span v-if="user.id_tahapan == 3" @click.prevent="progressForm(user.id, user.nim, user.name)" class="btn btn-sm btn-oblong btn-warning btn-block mg-b-10">{{ user.tahapan }}</span>
                                                <span v-if="user.id_tahapan == 4" @click.prevent="progressForm(user.id, user.nim, user.name)" class="btn btn-sm btn-oblong btn-info btn-block mg-b-10">{{ user.tahapan }}</span>
                                                <span v-if="user.id_tahapan == 5" @click.prevent="progressForm(user.id, user.nim, user.name)" class="btn btn-sm btn-oblong btn-primary btn-block mg-b-10">{{ user.tahapan }}</span>
                                                <span v-if="user.id_tahapan == 6" @click.prevent="progressForm(user.id, user.nim, user.name)" class="btn btn-sm btn-oblong btn-success btn-block mg-b-10">{{ user.tahapan }}</span>
                                                <span v-if="user.id_tahapan == 7" @click.prevent="progressForm(user.id, user.nim, user.name)" class="btn btn-sm btn-oblong btn-light btn-block mg-b-10">{{ user.tahapan }}</span>
                                            </td>
                                            <td>{{ user.progress_studi }} Semester</td>
                                            <td class="text-center ">
                                                <span v-if="user.undri == 1 && user.wisuda" class="text-success">Lulus</span>
                                                <span v-if="user.undri == 1 && !user.wisuda" class="text-danger">Undur Diri</span>
                                            </td>
                                            <td>{{ user.batas_studi }}</td>
                                            <td class="text-center">
                                                <button @click.prevent="editForm(user.id)" class="btn btn-secondary btn-sm mr-2"><i class="fe fe-eye"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage"
                                                :page-count="pagination.totalPages"
                                                :classes="pagination.bootstrapPaginationClasses"
                                                :labels="pagination.paginationAnchorTexts"
                                                v-on:change="getData()"></v-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
        <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="true"
                :paginate-elements-by-height="1400"
                :filename="'datapendaftar_'"
                :pdf-quality="1"
                :manual-pagination="true"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px"
                ref="html2Pdf"
            >
                <section slot="pdf-content">
                        <div class="card-body pb-0">
                            <!-- Asal Pendaftar -->
                            <div class="d-block mb-4">
                                <div class="h6 d-block mb-4 text-center font-weight-bold pt-2 text-dark">SURAT PERNYATAAN PENYELESAIAN STUDI DOKTOR</div>
                                <div class="row">
                                    <div class="col-4"><a style="font-size:14px">Yang bertanda tangan dibawah ini</a><a class="float-right">:</a></div>
                                    <div class="col-8"></div>
                                </div>

                                <div class="row mt-1">
                                    <div class="col-4"><a style="font-size:14px">Nama</a><a class="float-right">:</a></div>
                                    <div class="col-8"><a class="text-dark" style="font-size:14px">{{ print.name }}</a></div>
                                </div>
                                    
                                <div class="row mt-1">
                                    <div class="col-4"><a style="font-size:14px">NIM</a><a class="float-right">:</a></div>
                                    <div class="col-8"><a class="text-dark" style="font-size:14px">{{ print.username }}</a></div>
                                </div>
                                    
                                <div class="row mt-1">
                                    <div class="col-4"><a style="font-size:14px">Prodi/Fakultas/Sekolah</a><a class="float-right">:</a></div>
                                    <div class="col-8"><a class="text-dark" style="font-size:14px">Doktor Teknik Sipil - FTSL</a></div>
                                </div>
                                    
                                <div class="row mt-1">
                                    <div class="col-4"><a style="font-size:14px">Judul Disertasi</a><a class="float-right">:</a></div>
                                    <div class="col-8"><a class="text-dark" style="font-size:14px">{{ print.judul }}</a></div>
                                </div>
                                    
                                <div class="row mt-3">
                                    <div class="col-4"><a style="font-size:14px">Pembimbing</a><a class="float-right">:</a></div>
                                    <div class="col-8"><a class="text-dark" style="font-size:14px">{{ print.pembimbing }}</a></div>
                                </div>
                                    
                                <div class="row mt-1">
                                    <div class="col-4"><a style="font-size:14px">Lama Studi Berjalan</a><a class="float-right">:</a></div>
                                    <div class="col-8"><a class="text-dark" style="font-size:14px">{{ print.studi_berjalan != '' ? print.studi_berjalan : '-' }} Semester</a></div>
                                </div>
                                    
                                <div class="row mt-1">
                                    <div class="col-4"><a style="font-size:14px">Tahapan Lulus</a><a class="float-right">:</a></div>
                                    <div class="col-8"><a class="text-dark" style="font-size:14px">Tahapan {{ print.id_tahapan+1 }} ({{ print.tahapan }})</a></div>
                                </div>
                                    
                                <div class="row mt-1">
                                    <div class="col-4"><a style="font-size:14px">Batas Penyelesaian </a><a class="float-right">:</a></div>
                                    <div class="col-8"><a class="text-dark" style="font-size:14px">{{ print.batas }}</a></div>
                                </div>

                                <br><br>
                                <div class="row mt-1">
                                    <div class="col-12"><a style="font-size:14px">Dengan ini saya menyatakan bahwa apabila sampai dengan batas waktu tersebut 
                                    ({{ print.batas }}) belum melakukan {{ print.tahapan }} maka saya dianggap mengundurkan diri sebagai mahasiswa 
                                    Institut Teknologi Bandung.</a></div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-12"><a style="font-size:14px">Prodi Magister dan Doktor Teknik Sipil tidak akan mentolerir perpanjangan 
                                    masa studi berikutnya dengan alasan apapun.</a></div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-12"><a style="font-size:14px">Demikian surat pernyataan ini saya buat dengan sebenarnya dan dalam keadaan 
                                    sadar tanpa ada paksaan dari pihak manapun.</a></div>
                                </div>
                                <br><br>
                                <div class="row mt-1">
                                    <div class="col-12"><a style="font-size:14px">Bandung, {{ onlyDate(print.tanggal) }}</a></div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-12"><a style="font-size:14px">Yang membuat pernyataan,</a></div>
                                </div>
                                <br><br>
                                <div class="row mt-1">
                                    <div class="col-12"><a style="font-size:14px">Materai 10000</a></div>
                                </div>
                                <br><br>
                                <div class="row mt-1">
                                    <div class="col-12"><a style="font-size:14px">{{ print.name }}</a></div>
                                    <div class="col-12"><a style="font-size:14px">NIM {{ print.username }}</a></div>
                                </div>

                                    <div class="d-block mb-1 mt-1 text-center"><a style="font-size:14px">Mengetahui,</a></div>

                                <div class="row mt-3">
                                    <div class="col-6"><a class="text-center" style="font-size:14px">Dosen Wali</a></div>
                                    <div class="col-6"><a class="text-center" style="font-size:14px">Ketua Program Studi,</a></div>
                                </div>
                                <br><br><br><br>
                                <div class="row mt-3">
                                    <div class="col-6"><a class="text-center" style="font-size:14px">{{ print.pembimbing }}</a></div>
                                    <div class="col-6"><a class="text-center" style="font-size:14px">Dr.-Ing. Ir. Ediansjah Zulkifli, S.T., M.T.</a></div>
                                </div>
                            </div>
                        </div>
                </section>
        </vue-html2pdf>  
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    import moment from 'moment';
    import VueHtml2pdf from 'vue-html2pdf'

    export default {
        metaInfo: {
            title: 'FTSL',
            titleTemplate: '%s - Data Lulus / Undur Diri'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                fileName: "Mahasiswa Lulus/Undur Diri",
                header: "Data Mahasiswa Lulus/Undur Diri",
                dataPengguna: [],
                dataTahapan: [],
                dataPengutamaan: [],
                detailTahapan: null,
                fetching: true,
                checkPassword: 0,
                filterTahapan: '',
                filterPengutamaan: '',
                filterAngkatan: '',
                form: {
                    id: '',
                    name: '',
                    id_tahapan: '',
                    awal_studi_bulan: '',
                    awal_studi_tahun: '',
                    list_pembimbing: '',
                    judul: '',
                },
                detail:{
                    nim: '',
                    name: '',
                },
                print: {
                    judul: '',
                    pembimbing: '',
                    studi_berjalan: '',
                    tahapan: '',
                    batas: '',
                    id_tahapan: '',
                    tanggal: '',
                    name: '',
                    username: '',
                },
                pagination: {         
                    currentPage: 1,
                    totalPages: 0,
                    limit: 7,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'  
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                showSelectYear: true
                
            }
        },
        created() {
            this.getData();
            this.getTahapan();
            this.getPengutamaan();
        },
        components: {
            vPagination,
            VueHtml2pdf,
        },
        methods: {
            hide_modal() {
                this.$modal.hide('my-modal')
            },
            hide_detail() {
                this.$modal.hide('detail-tahapan')
            },
            // Get data data periode
            getData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/lulus/magister', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                        filterTahapan: this.filterTahapan,
                        filterPengutamaan: this.filterPengutamaan,
                        filterAngkatan: this.filterAngkatan,
                    }
                })
                    .then((response) => {
                        this.dataPengguna  = response.data.data;
                        this.totalPengguna = response.data.total;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },

            getTahapan() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/master/tahapan-magister', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                    }
                })
                    .then((response) => {
                        this.dataTahapan = response.data.data;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },

            getPengutamaan() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/master/pengutamaan-magister', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                    }
                })
                    .then((response) => {
                        this.dataPengutamaan = response.data.data;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },

            // save periode
            submitForm() {
                this.$http.post(this.baseUrl + 'admin/lulus/save-magister', this.form)
                    .then(() => {
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text: 'Data berhasil disimpan',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.$modal.hide('my-modal')
                        this.getData()
                        this.form.nama_kegiatan = ''
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });
            },
            // Edit data periode
            editForm(id) {
                this.$http.get(this.baseUrl + `admin/kemajuan/detail-magister?id=${id}`)
                    .then((response) => {
                        this.form.id = response.data.data[0].id;
                        this.form.name = response.data.data[0].name;
                        this.form.id_tahapan = response.data.data[0].id_tahapan;
                        this.form.awal_studi_bulan = response.data.data[0].awal_studi_bulan;
                        this.form.awal_studi_tahun = response.data.data[0].awal_studi_tahun;
                        this.form.list_pembimbing = response.data.data[0].list_pembimbing;
                        this.form.judul = response.data.data[0].judul;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                this.$modal.show('my-modal');
            },
            progressForm(id, nim, name) {
                this.$http.get(this.baseUrl + `admin/lulus/progress-magister?id=${id}`)
                    .then((response) => {
                        this.detailTahapan  = response.data.data;
                        this.detail.nim     = nim;
                        this.detail.name    = name;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
                this.$modal.show('detail-tahapan');
            },
            // Delete data periode
            postDelete(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    html: 'Data yang dihapus tidak dapat di kembalikan',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'admin/master/pengguna/delete-magister',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            if (response.data.status_pemakaian != 'digunakan') {
                                this.$swal(
                                    'Deleted!',
                                    'Data berhasil dihapus.',
                                    'success'
                                )
                            } else {
                                this.$swal(
                                    'Tidak dapat di hapus!',
                                    'Periode sudah digunakan.',
                                    'info'
                                )
                            }
                            this.getData();
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
            convertDate : function (date) {
                return moment(date, 'YYYY-MM-DD hh:mm').lang("id").format('DD MMMM YYYY hh:mm');
            },
            onlyDate : function (date) {
                return moment(date, 'YYYY-MM-DD').lang("id").format('DD MMMM YYYY');
            },
            generateReport () {
                this.$refs.html2Pdf.generatePdf()
            },
            updateSurat (id) {
                let uri = this.$apiconfig + 'admin/lulus/print-magister';
                this.$http.get(uri, {
                    params: {
                        id     : id,
                    }
                }).then(res => {					
                    let getResponse = res.data;
                    this.print.judul = getResponse.data.judul;
                    this.print.pembimbing = getResponse.data.pembimbing[0] ? getResponse.data.pembimbing[0] : '';
                    this.print.tahapan = getResponse.data.name;
                    this.print.batas = getResponse.data.batas_studi;
                    this.print.studi_berjalan = getResponse.data.progress_studi;
                    this.print.id_tahapan = getResponse.data.id_tahapan;
                    this.print.tanggal = getResponse.data.created_at;
                    this.print.name = getResponse.data.fullname;
                    this.print.username = getResponse.data.username;
                });
            },
            async getDataExport() {
                const response = await this.$http.get(this.baseUrl + `admin/lulus/export-magister`, {
                    params: {
                        filterTahapan: this.filterTahapan,
                        filterPengutamaan: this.filterPengutamaan,
                        filterAngkatan: this.filterAngkatan,
                        keywords: this.keywords,
                    }
                });
                return response.data.data;
            }
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>